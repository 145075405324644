import React from 'react'

const DOPPLEPAY_STORAGE_KEY = 'enable-dopplepay-testing'

/**
 * Enables DopplePay testing mode via local storage
 * This should be called when the special query parameter is detected
 */
export const enableDopplePayTesting = (): void => {
	if (typeof window !== 'undefined') {
		window.localStorage.setItem(DOPPLEPAY_STORAGE_KEY, 'true')
	}
}

/**
 * Disables DopplePay testing mode
 */
export const disableDopplePayTesting = (): void => {
	if (typeof window !== 'undefined') {
		window.localStorage.removeItem(DOPPLEPAY_STORAGE_KEY)
	}
}

/**
 * Checks if DopplePay should be visible
 * @returns boolean indicating if DopplePay should be visible
 */
export const isDopplePayVisible = (): boolean => {
	// Always return false during SSR to prevent hydration mismatches
	if (typeof window === 'undefined') return false

	// Use React's useEffect to check localStorage after hydration
	return false
}

/**
 * Hook to check if DopplePay should be visible
 * This handles hydration properly by using useEffect
 */
export const useIsDopplePayVisible = (): boolean => {
	const [isVisible, setIsVisible] = React.useState(false)

	React.useEffect(() => {
		const isEnabled = window.localStorage.getItem(DOPPLEPAY_STORAGE_KEY) === 'true'
		setIsVisible(isEnabled)
	}, [])

	return isVisible
}
