import React from 'react'

import { navigate } from 'gatsby'

import { CustomerContext } from '@context/customerContext'
import { WoocommerceContext } from '@context/woocommerceContext'

import { disableDopplePayTesting, enableDopplePayTesting } from '@utils/paymentMethods'

const SessionMonitor: React.FC = () => {
	const [, setCart] = React.useContext(WoocommerceContext).cart
	const { refreshCart } = React.useContext(WoocommerceContext)
	const [, setUser] = React.useContext(CustomerContext)

	React.useEffect(() => {
		// Check for DopplePay testing query parameter
		const params = new URLSearchParams(window.location.search)
		const dopplePayTest = params.get('dopplepay-test')

		if (dopplePayTest === 'true') {
			enableDopplePayTesting()
		} else if (dopplePayTest === 'false') {
			disableDopplePayTesting()
		}

		// Check session status and handle expired sessions
		const checkSession = () => {
			const session = localStorage.getItem('woo-session')
			const auth = localStorage.getItem('auth')

			if (!session) {
				// Session is gone, clear cart
				setCart(null)
				localStorage.removeItem('woo-next-cart')
			}

			if (!auth) {
				// Auth is gone, clear user
				setUser(null)

				// If on a protected route, redirect to login
				if (window.location.pathname.includes('/account')) {
					navigate('/account/login')
				}
			}
		}

		// Initial check
		checkSession()

		// Set up periodic checks
		const interval = setInterval(checkSession, 5 * 60 * 1000) // Every 5 minutes

		// Listen for storage events (in case another tab changes session)
		const handleStorageChange = (e: StorageEvent) => {
			if (e.key === 'woo-session' || e.key === 'auth') {
				checkSession()
			}
		}

		// Listen for tab focus
		const handleVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				refreshCart?.() // Optional chaining in case refreshCart isn't available
				checkSession()
			}
		}

		window.addEventListener('storage', handleStorageChange)
		document.addEventListener('visibilitychange', handleVisibilityChange)

		// Cleanup
		return () => {
			clearInterval(interval)
			window.removeEventListener('storage', handleStorageChange)
			document.removeEventListener('visibilitychange', handleVisibilityChange)
		}
	}, [setCart, setUser, refreshCart])

	return null // This component doesn't render anything
}

export default SessionMonitor
