import React from 'react'

import { WoocommerceContextType } from '@contracts/context/woocommerceContext'

export const WoocommerceContext = React.createContext<WoocommerceContextType>({
	cart: [null, () => null],
	cartLoading: [false, () => null],
	justAddedToCart: [false, () => null],
	refreshCart: () => null,
})
