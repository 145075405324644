import React from 'react'

import { WoocommerceCart } from '@contracts/context/woocommerceCart'

import { getCustomerCart } from '@localApollo/queries/getCart'

import { WoocommerceContext } from './woocommerceContext'

interface Props {
	children: React.ReactNode
}

export const WoocommerceProvider: React.FC<Props> = (props) => {
	const [cart, setCart] = React.useState<WoocommerceCart>(null)
	const [cartLoading, setCartLoading] = React.useState<boolean>(true)
	const [justAdded, setJustAdded] = React.useState<boolean>(false)

	const refreshCart = React.useCallback(async () => {
		try {
			const newCart = await getCustomerCart()
			setCart(newCart)
			localStorage.setItem(
				'woo-next-cart',
				JSON.stringify({
					cart: newCart,
					timestamp: Date.now(),
				})
			)
		} catch (error) {
			console.error('Failed to refresh cart:', error)
		} finally {
			setCartLoading(false)
		}
	}, [])

	// Initial cart setup
	React.useEffect(() => {
		const localCart = window?.localStorage.getItem('woo-next-cart')

		if (localCart) {
			const { cart: savedCart, timestamp } = JSON.parse(localCart)
			const now = Date.now()
			const fourHours = 4 * 60 * 60 * 1000

			if (now - timestamp < fourHours) {
				setCart(savedCart)
				setCartLoading(false)
			} else {
				refreshCart()
			}
		} else {
			refreshCart()
		}
	}, [])

	// Listen for storage events
	React.useEffect(() => {
		const handleStorageChange = (e: StorageEvent) => {
			if (e.key === 'woo-session' && !e.newValue) {
				refreshCart()
			}
		}

		window.addEventListener('storage', handleStorageChange)
		return () => window.removeEventListener('storage', handleStorageChange)
	}, [refreshCart])

	return (
		<WoocommerceContext.Provider
			value={{
				cart: [cart, setCart],
				cartLoading: [cartLoading, setCartLoading],
				justAddedToCart: [justAdded, setJustAdded],
				refreshCart,
			}}
		>
			{props.children}
		</WoocommerceContext.Provider>
	)
}
