// @ts-ignore
import fetch from 'isomorphic-fetch'
import { jwtDecode } from 'jwt-decode'
import isEmpty from 'lodash/isEmpty'

import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client'

/**
 * Middleware operation
 *
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 * If we have an auth token in localStorage, add it to the GraphQL request as an authorization header.
 */
export const middleware = new ApolloLink((operation, forward) => {
	let headersData: any = {}

	const session = typeof window !== 'undefined' ? localStorage.getItem('woo-session') : null
	const auth = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('auth')) : null
	const token = !isEmpty(auth) ? auth.authToken : null

	if (session) {
		headersData['woocommerce-session'] = `Session ${session}`
	}

	if (token) {
		const decoded = jwtDecode<any>(token)
		const expireTime = new Date(decoded.exp * 1000).getTime()
		const now = Date.now()

		if (expireTime < now) {
			// Token expired - trigger refresh logic
			localStorage.removeItem('auth')
			localStorage.removeItem('woo-session')
			window.dispatchEvent(new Event('storage'))
			return forward(operation)
		}

		headersData.authorization = `Bearer ${token}`
	}

	operation.setContext(({ headers = {} }) => ({
		headers: {
			...headers,
			...headersData,
		},
	}))

	return forward(operation)
})

/**
 * Afterware operation.
 *
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
export const afterware = new ApolloLink((operation, forward) => {
	return forward(operation).map((response) => {
		const context = operation.getContext()
		const {
			response: { headers },
		} = context
		const session = headers.get('woocommerce-session')

		if (session) {
			if ('false' === session) {
				// Session destroyed - clear everything
				localStorage.removeItem('woo-session')
				localStorage.removeItem('woo-next-cart')
				window.dispatchEvent(new Event('storage'))
			} else if (localStorage.getItem('woo-session') !== session) {
				// New session - update storage
				localStorage.setItem('woo-session', session)

				// Trigger cart refresh
				const timestamp = Date.now()
				const existingCart = localStorage.getItem('woo-next-cart')
				if (existingCart) {
					const cartData = JSON.parse(existingCart)
					localStorage.setItem(
						'woo-next-cart',
						JSON.stringify({
							...cartData,
							timestamp,
						})
					)
				}
			}
		}

		return response
	})
})

// Apollo GraphQL client.
export const client = new ApolloClient({
	link: middleware.concat(
		afterware.concat(
			createHttpLink({
				uri: `${process.env.GATSBY_WORDPRESS_PROTOCOL}://${process.env.GATSBY_WORDPRESS_URL}/graphql`,
				fetch: fetch,
			})
		)
	),
	cache: new InMemoryCache(),
})
