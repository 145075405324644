module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Versaclimber","short_name":"Versaclimber","start_url":"/","background_color":"#E62A2C","theme_color":"#E62A2C","display":"standalone","icon":"src/images/icon.png","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-32},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.versaclimber.co.uk/graphql","html":{"useGatsbyImage":true,"imageQuality":80,"createStaticFiles":true,"generateWebpImages":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"generateAvifImages":false,"placeholderType":"dominantColor"},"verbose":false,"production":{"allow404Images":true,"allow401Images":true,"hardCacheMediaFiles":false},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"debug":{"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"schema":{"perPage":20,"requestConcurrency":32,"previewRequestConcurrency":5,"timeout":2000000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"type":{"Comment":{"limit":0},"Tag":{"limit":0},"PaymentGateway":{"limit":0},"TaxRate":{"limit":0},"PostFormat":{"limit":0},"Refund":{"limit":0},"UserRole":{"limit":0},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N3W24K7","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-16744034747"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"exclude":["/preview/**"],"delayOnRouteUpdate":0,"respectDNT":true,"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
